@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px) rem(90px);
    background-color: #eaeef3;
}

.styngs {
    margin-bottom: rem(40px);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: rem(10px);
}

.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - rem(60px));
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1);
    &.open {
        width: calc(100% - rem(240px));
    }
}
